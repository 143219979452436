import React, { useState, useEffect } from "react";
import slika1 from "../images/hero/biograd.jpg";
import slika2 from "../images/hero/plaza-soline.png";
import slika3 from "../images/hero/dalmaland.jpg";
import building from "../images/katalog-slike/zgrada-1.png";
import "../styles/Hero.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import facebook from "../images/socials/facebook-white.svg";
import instagram from "../images/socials/instagram-white.svg";
import whatsUpp from "../images/socials/whatsupp-white.svg";
import buildings from "../images/katalog-slike/zgrade.png";
import discountSticker from "../images/hero/akcijska-ponuda.png";
import discountStickerEng from "../images/hero/akcijska-ponuda-eng.png";
import backgroundImageLeft from "../images/katalog-slike/zgrade-s-pozadinom-2.png";

const Hero2 = () => {
  const { t, i18n } = useTranslation(); // Dohvaćanje t funkcije za prijevod
  const [buildingsOrBuilding, setBuildingsOrBuilding] = useState("buildings");

  const [choosenBuilding, setChoosenBuilding] = useState();

  const [showInfoBoxes, setShowInfoBoxes] = useState([false, false]);

  const [slides, setSlides] = useState([
    {
      id: 1,
      image: {
        src: slika1,
        alt: "Biograd na Moru",
      },
      description: "Town Centre",
    },
    {
      id: 2,
      image: {
        src: slika2,
        alt: "Plaža Soline",
      },
      description: "Beach Soline",
    },
    {
      id: 3,
      image: {
        src: slika3,
        alt: "Dalmaland Aquapark",
      },
      description: "Dalmaland Aquapark",
    },
  ]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  useEffect(() => {
    // show info boxes after 2 seconds
    const t1 = setTimeout(() => {
      const t2 = setTimeout(() => {
        setShowInfoBoxes([true, true]);
      }, 500);
      setShowInfoBoxes([true, false]); // show info boxes after 2 seconds

      return () => clearTimeout(t2);
    }, 500);
    return () => clearTimeout(t1);
  }, []);

  return (
    <section className="hero-2">
      <div className="hero-background-image-left">
        <img src={backgroundImageLeft} alt="Zgrada Hero Pozadina" />
      </div>
      <div className="hero-slides">
        {slides.map((slide, index) => (
          <div
            className="hero-slide"
            key={index}
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay={index * 200}
          >
            <img src={slide.image.src} alt={slide.image.alt} />
            <div className="hero-slide-description">
              <p>{t(slide.description)}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="discount-sticker">
        <img
          src={i18n.language === "en" ? discountStickerEng : discountSticker}
          alt="Discount sticker"
        />
      </div>

      <Link
        className="hero-location"
        to={`/location`}
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="0"
        data-aos-offset="0"
      >
        <div className="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.8}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
          />
        </svg>

        <h4>{t("Biograd na Moru")}</h4>

        <p>Put Grande</p>

        <h3>{t("Atractive places near the apartments:")} </h3>
        <ul>
          <li>{t("Town Centre")}</li>
          <li>{t("Dalmaland Aquapark ")}</li>
          <li>{t("Soline Beach")}</li>
        </ul>
      </Link>

      <div className="hero-text" data-aos="fade-up" data-aos-duration="500">
        <h1 className="hero-title">
          {t(
            "You are looking for a premium apartment in an attractive location by the sea"
          )}{" "}
        </h1>
      </div>

      <div className="hero-social-media">
        <h4>{t("Follow us on social media")}:</h4>
        <a href="https://www.facebook.com">
          <img src={facebook} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/mp_premium_real_estate/">
          <img src={instagram} alt="Instagram" />
        </a>
      </div>
    </section>
  );
};

export default Hero2;
