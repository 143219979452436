import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import AboutProjectSection from "../components/AboutProjectSection";
import Eksterrier from "../components/Eksterrier";
import ContactSection from "../components/ContactSection";
import Interrier from "../components/Interrier";
import SurroundingPlaces from "../components/SurroundingPlaces";
import ChooseApartment from "../components/ChooseApartment";
import LocationSection from "../components/LocationSection";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import PDFLinks from "../components/PDFLinks";
import AboutProjectSection2 from "../components/AboutProjectSection2";
import Hero2 from "../components/Hero2";
import { serverUrl } from "../utils/domainName";
import NewsSection from "../components/NewsSection";
import { useAppContext } from "../AppContext";

const Home = () => {
  const { news, setNews } = useAppContext();
  const { t } = useTranslation();
  const [loadingNews, setLoadingNews] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
    fetchNews();
  }, []);

  const fetchNews = () => {
    fetch(`${serverUrl}api/news`)
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
        setLoadingNews(false);
      })
      .catch((err) => {
        fetchNews();
      });
  };

  return (
    <main className="home page">
      <Hero2 />
      <AboutProjectSection2 />
      <ChooseApartment />
      <PDFLinks />
      <SurroundingPlaces />
      <LocationSection />
      <Eksterrier />
      <div className="interrier-proposal-information">
        <h1>
          {t(
            "On the images, only the furniture projections are displayed, and the apartment comes unfurnished."
          )}
        </h1>
        <h3>
          {t(
            "Importantly, we offer a complete apartment furnishing service tailored to the owner's preferences and budget, with a turnkey system, price upon agreement."
          )}
        </h3>
      </div>
      <Interrier />
      <NewsSection news={news} loading={loadingNews} />
      <ContactSection />
    </main>
  );
};

export default Home;
