import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../styles/NewsCard.scss";

const NewsCard = ({ key, news }) => {
  const { t, i18n } = useTranslation();

  function truncateText(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }

    return str.slice(0, str.lastIndexOf(" ", maxLength)) + "...";
  }

  const language = i18n.language;

  const formatDate = (date) => {
    const dateObj = new Date(date);

    // replace the / with . in the date string
    const dateString = dateObj.toLocaleDateString().replace(/\//g, ".");
    return dateString;
  };

  return (
    <Link
      className="news-card"
      key={key}
      to={`/news/${news.slug}`}
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-delay="0"
      data-aos-offset="0"
    >
      <div className="news-card-image background-image-loading">
        <img src={news.images[0].url} alt={news.title.hr + key} />
      </div>
      <div className="news-card-info">
        <Link className="btn-rounded black" to={`/news/${news.slug}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </Link>

        <h3 className="appartment-card-title">
          {language === "en" ? news.title.en : news.title.hr}
        </h3>
        <h4> {formatDate(news.date) + "."} </h4>
        <p className="news-description">
          {language === "en"
            ? truncateText(news.description.en, 230)
            : truncateText(news.description.hr, 230)}
        </p>
      </div>
    </Link>
  );
};

export default NewsCard;
