import React, { useState } from "react";
import "../styles/AboutProjectSection.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import slika1 from "../images/katalog-slike/zgrade-s-pozadinom-1.png";
import slika2 from "../images/katalog-slike/zgrade-s-pozadinom-2.png";
import slika3 from "../images/katalog-slike/zgrade-s-pozadinom-3.png";
import slika4 from "../images/katalog-slike/zgrade-s-pozadinom-4.png";
import slika5 from "../images/eksterijer/eksterijer-1.png";
import slika6 from "../images/eksterijer/eksterijer-2.png";

import Aos from "aos";
import "aos/dist/aos.css";
import BuildingsChooser from "./BuildingsChooser";

const AboutProjectSection2 = () => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  return (
    <section className="about-project-section">
      <h1
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="0"
        data-aos-offset="0"
      >
        {t("Construction project of a building with 6 apartments.")}
      </h1>
      <div
        className="link-and-description"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="0"
      >
        {" "}
        <div className="description">
          <p>
            {t(
              "The combination of modern design, Middle Dalmatian architecture, and superior construction quality makes this residential building in a peaceful part of Biograd particularly appealing. The property is equally distant (a 10-minute walk) from three key locations: the center of Biograd; Soline, the most beautiful beach in this part of Dalmatia; and Dalmaland, the most attractive amusement/water park in all of Dalmatia."
            )}
            {showMore && (
              <>
                <br /> <br />
                <br />
                {t(
                  "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which gives the location an extremely attractive position. The building extends over two floors and has six apartments, built of top quality materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price of EUR 199,000 (unit price for an apartment on the upper floors) and EUR 249,000 (unit price for two apartments on the ground floor). This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We highlight the modern design and strategic location, which will become even more attractive over time due to its ideal position."
                )}
              </>
            )}
          </p>

          <button onClick={() => setShowMore(!showMore)}>
            {showMore ? t("Show less") : t("Show more")}
          </button>
        </div>
        <Link to="/project" className="btn-rounded black">
          {t("Read more")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
      <div className="project-building">
        <BuildingsChooser />
        <div className="important-info-cards">
          <div
            className="important-info-card"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="0"
            data-aos-offset="0"
          >
            <div className="important-info-card-title">
              <h4>199.000</h4>
              <h5>{t("€ price from")}</h5>
            </div>
            <p>{t("Three-room apartments on the first floor.")}</p>
          </div>
          <div
            className="important-info-card"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-offset="0"
            data-aos-delay="0"
          >
            {" "}
            <div className="important-info-card-title">
              <h4>249.000</h4>
              <h5>{t("€ for apartments (2) on ground floor.")}</h5>
            </div>
            <p>
              {t(
                "Attractive garden, ideal for a relaxation zone: installing a swimming pool or jacuzzi."
              )}
            </p>
          </div>
          <div
            className="important-info-card"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-offset="0"
            data-aos-delay="0"
          >
            {" "}
            <div className="important-info-card-title">
              <h4>219.000</h4>
              <h5>{t("€ price from for apartments (2) on 2. floor.")}</h5>
            </div>
            <p>{t("Apartments with a beautiful view.")}</p>
          </div>
          <div
            className="important-info-card"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-offset="0"
            data-aos-delay="0"
          >
            {" "}
            <div className="important-info-card-title">
              <h4>2</h4>
              <h5>{t(" parking spots")}</h5>
            </div>
            <p>{t("Every apartment has 2 parking spots.")}</p>
          </div>
        </div>
      </div>

      <div className="images">
        <a
          href={slika1}
          target="_blank"
          rel="noopener noreferrer"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="0"
        >
          <img src={slika1} alt="Slika 1" />
          <div className="on-hover">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
              />
            </svg>

            <p>{t("Click to see more")}</p>
          </div>
        </a>

        <div
          className="images-col"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="0"
        >
          <a href={slika3} target="_blank" rel="noopener noreferrer">
            <img src={slika3} alt="Slika 3" />
            <div className="on-hover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
                />
              </svg>

              <p>{t("Click to see more")}</p>
            </div>
          </a>
          <a href={slika4} target="_blank" rel="noopener noreferrer">
            <img src={slika4} alt="Slika 4" />
            <div className="on-hover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
                />
              </svg>

              <p>{t("Click to see more")}</p>
            </div>
          </a>
        </div>
      </div>
      <div className="images">
        <a
          href={slika5}
          target="_blank"
          rel="noopener noreferrer"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="0"
        >
          <img src={slika5} alt="Slika 5" />
          <div className="on-hover">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
              />
            </svg>

            <p>{t("Click to see more")}</p>
          </div>
        </a>

        <a href={slika6} target="_blank" rel="noopener noreferrer">
          <img src={slika6} alt="Slika 6" />
          <div className="on-hover">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
              />
            </svg>

            <p>{t("Click to see more")}</p>
          </div>
        </a>
      </div>
    </section>
  );
};

export default AboutProjectSection2;
