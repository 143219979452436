import React from "react";

import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import interijer1 from "../images/katalog-slike/interijer-1.png";
import interijer2 from "../images/katalog-slike/interijer-2.png";
import interijer3 from "../images/katalog-slike/interijer-3.png";
import interijer4 from "../images/katalog-slike/interijer-4.png";
import interijer5 from "../images/katalog-slike/interijer-5.png";
import interijer6 from "../images/katalog-slike/interijer-6.png";
import interijer7 from "../images/katalog-slike/interijer-7.png";
import interijer8 from "../images/katalog-slike/interijer-8.png";
import eksterijer1 from "../images/eksterijer/eksterijer-1.png";
import eksterijer2 from "../images/eksterijer/eksterijer-2.png";
import eksterijer3 from "../images/eksterijer/eksterijer-3.png";
import "../styles/Appartment.scss";
import ImagesScroll from "../components/ImagesScroll";
import apartmanS1 from "../images/katalog-slike/apartman-s1.png";
import apartmanS2 from "../images/katalog-slike/apartman-s2.png";
import apartmanS3 from "../images/katalog-slike/apartman-s3.png";
import apartmanS4 from "../images/katalog-slike/apartman-s4.png";
import apartmanS5 from "../images/katalog-slike/apartman-s5.png";
import apartmanS6 from "../images/katalog-slike/apartman-s6.png";
import ContactSection from "../components/ContactSection";
import Interrier from "../components/Interrier";
import Eksterrier from "../components/Eksterrier";
import SurroundingPlaces from "../components/SurroundingPlaces";
import LocationSection from "../components/LocationSection";
import ChooseApartment from "../components/ChooseApartment";
import Aos from "aos";
import "aos/dist/aos.css";
import RoomSection from "../components/RoomSection";
import { serverUrl } from "../utils/domainName";
import ImagesScroll2 from "../components/ImagesScroll";

const Appartment = () => {
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const [appartment, setAppartment] = useState(null);
  const imagesContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const language = i18n.language;

  useEffect(() => {
    fetchApartment();
  }, [slug]);

  const fetchApartment = () => {
    fetch(`${serverUrl}api/apartments/${slug} `, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setAppartment(data);
        setLoading(false);
      })
      .catch((err) => {
        fetchApartment();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
  }, []);

  const calculateQuadratures = () => {
    if (!appartment) return 0;

    let quadratures = 0;
    appartment.quadraturesPerRoom.forEach((quadrature) => {
      quadratures += quadrature.quadrature * quadrature.coeficient;
    });
    // round to 2 decimal places
    return quadratures.toFixed(2);
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);

    // replace the / with . in the date string
    const dateString = dateObj.toLocaleDateString().replace(/\//g, ".");
    return dateString + ".";
  };

  const imageTagsOptions = [
    "DNEVNI BORAVAK",
    "KUHINJA",
    "KUPAONICA",
    "TERASA",
    "SPAVAĆA SOBA",
    "ULAZ",
    "EKSTERIJER",
    "OSTALO",
  ];

  return (
    <main className="apparment-page page">
      <ImagesScroll images={appartment?.images} />
      <section
        className="appartment-info-and-plan-of-the-rooms"
        style={loading ? { opacity: "0" } : { opacity: "1" }}
      >
        <div
          className={`apartment-info`}
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          <h2>
            {language === "en" ? appartment?.title.en : appartment?.title.hr}
          </h2>
          <div className="apprtment-floor-and-price">
            <h4>
              {appartment?.floor === 0
                ? t("Ground floor")
                : appartment?.floor === 1
                ? t("First floor")
                : t("Second floor")}
            </h4>
            <h3>{appartment?.price.toLocaleString("de-DE")} €</h3>
          </div>
          <div className="appartment-quadratures-and-description">
            <div className="apartment-quadratures">
              {appartment?.quadraturesPerRoom.map((room, roomIndex) => (
                <p key={room.id + roomIndex}>
                  <span>
                    {room.id + ": "} {t(room.room)}
                  </span>
                  : {room.quadrature}m²
                </p>
              ))}
            </div>
            <p className="apartment-description">
              {language === "en"
                ? appartment?.description.en
                : appartment?.description.hr}
            </p>
          </div>
          <div className="apartment-date-and-total-quadratures">
            <div className="apartment-date-and-total-quadratures-item">
              <h5>
                {calculateQuadratures()}
                m²
              </h5>
              <h6> {t("Total quadrature")} </h6>
            </div>
            <div className="apartment-date-and-total-quadratures-item">
              <h5>{formatDate(appartment?.dateBuiltStarted)}</h5>
              <h6>{t("Date built Started")} </h6>
            </div>
            <div className="apartment-date-and-total-quadratures-item">
              <h5>{formatDate(appartment?.dateBuiltEnds)}</h5>
              <h6>{t("date of possible move-in")} </h6>
            </div>
          </div>
        </div>
        <div
          className="ground-plan"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          <img src={appartment?.floorPlan} alt={appartment?.title.en} />
        </div>
      </section>{" "}
      <div className="interrier-proposal-information">
        <h1>
          {t(
            "On the images, only the furniture projections are displayed, and the apartment comes unfurnished."
          )}
        </h1>
        <h3>
          {t(
            "Importantly, we offer a complete apartment furnishing service tailored to the owner's preferences and budget, with a turnkey system, price upon agreement."
          )}
        </h3>
      </div>
      <Interrier />
      {imageTagsOptions.map((option, index) => {
        // get images from apartment object that has images.tag = option
        const images = appartment?.images.filter(
          (image) => image.tag === option
        );

        if (images?.length > 0) {
          return (
            <RoomSection
              key={index}
              title={option}
              theme={"black"}
              images={images}
              subtitle="On the images, only the furniture projections are displayed, and the apartment comes unfurnished."
              description={
                "Importantly, we offer a complete apartment furnishing service tailored to the owner's preferences and budget, with a turnkey system, price upon agreement."
              }
            />
          );
        }

        return null;
      })}
      <SurroundingPlaces />
      <LocationSection />
      <ContactSection />
    </main>
  );
};

export default Appartment;
