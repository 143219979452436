import React from "react";
import { useTranslation } from "react-i18next"; // Uvoz useTranslation
import "../styles/Footer.scss";
import { NavLink } from "react-router-dom";
import { changeLanguage } from "i18next";
import english from "../images/flags/english.webp";
import croatian from "../images/flags/croatian.png";
import Select from "./Select";
import facebook from "../images/socials/facebook-white.svg";
import instagram from "../images/socials/instagram-white.svg";
import whatsUpp from "../images/socials/whatsupp-white.svg";
import slika1 from "../images/katalog-slike/zgrade-s-pozadinom-1.png";
import slika2 from "../images/katalog-slike/zgrade-s-pozadinom-2.png";
import slika3 from "../images/katalog-slike/zgrade-s-pozadinom-3.png";
import slika4 from "../images/katalog-slike/zgrade-s-pozadinom-4.png";
import slika5 from "../images/katalog-slike/interijer-1.png";
import slika6 from "../images/katalog-slike/interijer-4.png";
import MapPoint from "./MapPoint";
const Footer = () => {
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod

  //

  const optionsJSX = [
    <>
      <img src={english} alt="English flag" />
      <span>English</span>
    </>,
    <>
      <img src={croatian} alt="Croatian flag" />
      <span>Hrvatski</span>
    </>,
  ];
  return (
    <footer>
      <section className="footer-column-1">
        <h2>{t("MP - Premium")}</h2>
        <h3>{t("Real Estate")}</h3>
        <nav>
          <ul>
            <h4>{t("Menu")}</h4>
            <li className="link">
              <NavLink to="/" activeClassName="active">
                {t("Home")}
              </NavLink>
            </li>
            <li className="link">
              <NavLink to="/project" activeClassName="active">
                {t("Project")}
              </NavLink>
            </li>
            <li className="link">
              <NavLink to="/appartments" activeClassName="active">
                {t("Appartments")}
              </NavLink>
            </li>
            <li className="link">
              <NavLink to="/location" activeClassName="active">
                {t("Location")}
              </NavLink>
            </li>{" "}
            <li className="link">
              <NavLink to="/contact-us" activeClassName="active">
                {t("Contact")}
              </NavLink>
            </li>{" "}
          </ul>
          <ul>
            <h4>{t("About Us")}</h4>

            <li>
              <a href="mailto:info@mp-premium.com">
                {t("Email")}: <span>info@mp-premium.com</span>
              </a>{" "}
            </li>

            <li>
              <a href="tel:+385919475006">
                {t("Phone")}: <span>+385 91 947 5006</span>
              </a>
            </li>

            <li>
              <a>MP PREMIUM REAL ESTATE d.o.o.</a>
            </li>
          </ul>
        </nav>

        <div className="footer-social-media-and-page-language">
          <div className="footer-social-media">
            <a
              href="https://www.facebook.com/MPPremiumRealEstate/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/mp_premium_real_estate/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </a>
            {/*
            <a href="https://www.whatsapp.com">
              <img src={whatsUpp} alt="WhatsUpp" />
            </a>
            */}
          </div>

          <div className="footer-page-language">
            <p>{t("Change language")}:</p>
            <Select
              optionsJSX={optionsJSX}
              values={["en", "hr"]}
              onChange={(e) => changeLanguage(e.target.value)}
            />
          </div>
        </div>

        <div className="footer-rights">
          <h5>© 2024 MP Premium Real Estate d.o.o.</h5>
          <p>{t("All rights reserved")}</p>
        </div>
      </section>{" "}
      <section className="footer-column-2">
        <h3>{t("About project")}</h3>

        <h4>{t("Biograd na moru")}</h4>
        <p>Put Grande</p>
        <p>
          {t(
            "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which gives the location an extremely attractive position. The facility is located 10-15 minutes on foot from the three main attractions of Biograd and its surroundings: Soline beach, as the most beautiful beach in this part of Dalmatia, Aquapark Dalmaland, the largest amusement park in all of Dalmatia, and the city center. For the above reasons, the location is ideal for relaxation and fun. The building extends over two floors and has six apartments, built of top quality materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price of EUR 199,000 (unit price for an apartment on the upper floors) and EUR 249,000 (unit price for two apartments on the ground floor). This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We highlight the modern design and strategic location, which will become even more attractive over time due to its ideal position."
          )}
        </p>

        <div className="footer-images">
          <a href={slika1} target="_blank" rel="noopener noreferrer">
            <img src={slika1} alt="Slika 1" />
          </a>
          <a href={slika2} target="_blank" rel="noopener noreferrer">
            <img src={slika2} alt="Slika 2" />
          </a>
          <a href={slika3} target="_blank" rel="noopener noreferrer">
            <img src={slika3} alt="Slika 3" />
          </a>
          <a href={slika4} target="_blank" rel="noopener noreferrer">
            <img src={slika4} alt="Slika 4" />
          </a>
          <a href={slika5} target="_blank" rel="noopener noreferrer">
            <img src={slika5} alt="Slika 5" />
          </a>
          <a href={slika6} target="_blank" rel="noopener noreferrer">
            <img src={slika6} alt="Slika 6" />
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
