import React, { useEffect, useState } from "react";
import { useAppContext } from "../AppContext";
import ImagesScroll from "../components/ImagesScroll";
import { useLocation } from "react-router-dom";
import { serverUrl } from "../utils/domainName";
import ImagesScroll2 from "../components/ImagesScroll";
import { useTranslation } from "react-i18next";
import "../styles/News.scss";
const News = () => {
  const { t, i18n } = useTranslation();
  const [n, setN] = useState(null);
  const { news } = useAppContext();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    getNFromNews();
  }, []);

  const slug = pathname.split("/")[2];

  const getNFromNews = () => {
    // if the news has a slug that matches the slug in the url, set the news to the news with that slug
    const n = news.find((n) => n.slug === slug);

    if (n) {
      setN(n);
    } else {
      fetchN();
    }
  };

  const fetchN = () => {
    fetch(`${serverUrl}api/news/${slug}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setN(data);
      })
      .catch((err) => {
        fetchN();
      });
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);

    // replace the / with . in the date string
    const dateString = dateObj.toLocaleDateString().replace(/\//g, ".");
    return dateString;
  };

  return (
    <>
      <main className="n-page page">
        <ImagesScroll images={n?.images} />
        <section className="news-content">
          <div className="news-content-header">
            <h1>{i18n.language === "hr" ? n?.title.hr : n?.title.en}</h1>
            <h3> {formatDate(n?.date) + "."}</h3>
          </div>
          <div className="description">
            <p>
              {i18n.language === "hr" ? n?.description.hr : n?.description.en}
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default News;
