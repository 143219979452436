import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import hero from "../images/hero/start-of-construction-1.png";
import "../styles/AllNews.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import NewsCard from "../components/NewsCard";
import { serverUrl } from "../utils/domainName";
import LoadingSpinner from "../components/LoadingSpinner";

const AllNews = () => {
  const [news, setNews] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
    fetchNews();
  }, []);

  const fetchNews = () => {
    fetch(`${serverUrl}api/news`)
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
        setLoading(false);
      })
      .catch((err) => {
        fetchNews();
      });
  };
  return (
    <main className="news-page page">
      <section className="sub-hero-ver-2">
        <img src={hero} alt="Zgrade" className="background-img" />
        <h1
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          {t("Look at our latest news")}
        </h1>
        <p
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          {t("Look at news about building process.")}
        </p>
      </section>
      <section className="news">
        <div className="news-container">
          {loading && <LoadingSpinner />}
          {news.map((n, index) => (
            <NewsCard index={index} news={n} />
          ))}
        </div>
      </section>{" "}
    </main>
  );
};

export default AllNews;
